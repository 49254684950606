import * as api from '@/api'
import { ApiResponse } from '@/api'
import { UserRole, UserStatus } from '@/types/user.d'

// 계정 목록 조회 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053341185
export function searchAccountsList(params: {
  page?: number
  pageSize?: number
  query?: {
    email?: string
    name?: string
    role?: UserRole | string | undefined
    status?: UserStatus | string | undefined
    startDate?: string
    endDate?: string
  }
  sort?: {
    createdAt: 'asc' | 'desc'
  }
}): Promise<ApiResponse<any>> {
  const url = `/v1/users`
  return api.get(url, {
    ...params,
    query: JSON.stringify(params.query || {}),
    sort: JSON.stringify(params.sort || { createdAt: 'desc' }),
  })
}

// 계정 조회 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053734416/API
export function getAccount(params: { idx: number }): Promise<ApiResponse<any>> {
  const url = `/v1/users/${params.idx}`
  return api.get(url)
}

// 사장님 계정 직접 생성 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053734401/API
export function createAccount(params: {
  email: string
  password: string
  name: string
}): Promise<ApiResponse<any>> {
  const url = `/v1/users`
  return api.post(url, params)
}

// 사장님 계정 삭제 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2080800874/MANAGER
export function deleteManager(params: {
  idx: number
}): Promise<ApiResponse<any>> {
  const url = `/v1/users/${params.idx}`
  return api.delete(url)
}

// 계정 수정(상태, 권한, 이름, 비밀번호) : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053373968/API
export function patchAccount(params: {
  idx: number | undefined
  body: {
    status?: UserStatus
    role?: UserRole
    name?: string
    password?: string
  }
}): Promise<ApiResponse<any>> {
  const url = `/v1/users/${params.idx}`
  return api.patch(url, params.body)
}

// 연결 매장 추가 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053996545
export function addRestaurant(params: {
  idx: number | undefined
  restaurantIdx: number
}): Promise<ApiResponse<any>> {
  const url = `/v1/users/${params.idx}/restaurant`
  return api.put(url, { restaurantIdx: params.restaurantIdx })
}

// 연결 매장 삭제 : https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2053996554
export function deleteRestaurant(params: {
  idx: number | undefined
  restaurantIdx: number
}): Promise<ApiResponse<any>> {
  console.log(params.restaurantIdx)
  const url = `/v1/users/${params.idx}/restaurant`
  return api.delete(url, { restaurantIdx: params.restaurantIdx })
}

// 오피스 - 예금주(실명) 조회 API
export function getAccountHolder(params: {
  bankCD: string
  account: string
}): Promise<ApiResponse<any>> {
  const url = '/v1/coocon/account-holder-cert'
  return api.post(url, params)
}

// OTP 인증
export function otpAuth(otp: string) {
  const url = '/v1/auth/verify-otp'
  return api.get(url, { otp })
}
