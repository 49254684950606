import * as api from '@/api'

// 인증API 문서
// https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2040135972/API

// 로그인 결과
export interface TokenData {
  accessToken: string
  refreshToken: string
}

export interface VoiceAlarm {
  newOrder: boolean
}

export interface Setting {
  voiceAlarm: VoiceAlarm
}

// 회원가입 결과
export interface SignupResult {
  _id: string
  name: string
  email: string
  loginType: string
  role: string
  status: string
  idx: string
  setting: Setting
  createdAt: Date
  updatedAt: Date
}

// 로그인
export async function login(params: {
  email: string
  password: string
}): Promise<api.ApiResponse<TokenData>> {
  const url = '/v1/auth/login'
  return api.post(url, params)
}

// 로그아웃
export async function logout(idx: string): Promise<api.ApiResponse<Number>> {
  const url = '/v1/auth/logout'
  return api.post(url, { idx })
}

// 비밀번호 재설정 이메일 전송
export async function sendResetPasswordEmail(params: {
  email: string
}): Promise<api.ApiResponse<boolean>> {
  const url = '/v1/auth/verify/reset-password'
  return api.post(url, params)
}

// 이메일 인증
export async function requestEmailVerify(params: {
  email: string
}): Promise<api.ApiResponse<boolean>> {
  const url = '/v1/auth/verify'
  return api.post(url, params)
}

// 이메일 인증 검증
export async function confirmEmailVerify(params: {
  email: string
  verifyKey: string
}): Promise<api.ApiResponse<boolean>> {
  const url = '/v1/auth/verify/confirm'
  return api.post(url, params)
}

// 인증 연장
export async function refreshToken(
  token: string,
): Promise<api.ApiResponse<TokenData>> {
  const url = '/v1/auth/refresh'
  return api.post(url, { refreshToken: token })
}

// 회원가입
export async function signup(params: {
  email: string
  password: string
  name: string
  isVerify: boolean
}): Promise<api.ApiResponse<SignupResult>> {
  const url = '/v1/auth/signup'
  return api.post(url, params)
}

export interface CooconUser {
  _id: string
  mobileUserIdx: number
  email: string
  isValid: boolean
  createdAt: string
  expiredAt: string
}

// 쿠콘 사용자 정보변경 요청 생성 (메일발송) API
export function createCooconUserWithUpdateEmail(params: {
  mobileUserIdx: number
  phone: string
  email: string
  expiresHours: number
}): Promise<api.ApiResponse<CooconUser>> {
  const url = '/v1/coocon-user-update-emails'
  return api.post(url, params)
}

// 토큰 유저 조회 (me) API
export function getMe(): Promise<api.ApiResponse<any>> {
  const url = '/v1/auth/me'
  return api.get(url)
}

// 매장의 연결된 유저 리스트 조회
export function getRestaurantUsers({
  restaurantIdx,
}: {
  restaurantIdx: number
}): Promise<api.ApiResponse<any>> {
  const url = '/v1/users/restaurant'
  return api.get(url, { restaurantIdx })
}

// 비밀번호 재설정 인증 API
// https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2045411363/API
export function verifyResetPasswordKey(params: {
  verifyKey: string
}): Promise<any> {
  const url = '/v1/auth/check-resettable-password'
  return api.post(url, params)
}

// 계정 비밀번호 변경
// https://mealantcloud.atlassian.net/wiki/spaces/PI/pages/2070282921/API
export function resetPassword(params: {
  newPassword: string
  verifyKey: string
}): Promise<any> {
  const url = '/v1/users/reset-password'
  return api.patch(url, params)
}

export const userKeys = {
  all: ['user'] as const,
  restaurants: () => [...userKeys.all, 'restaurant'] as const,
  restaurant: (restaurantIdx: number) =>
    [...userKeys.restaurants(), restaurantIdx] as const,
}
