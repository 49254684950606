import {
  addRestaurant,
  createAccount,
  deleteManager,
  deleteRestaurant,
  getAccount,
  getAccountHolder,
  otpAuth,
  patchAccount,
  searchAccountsList,
} from '../service/accounts'
import { useRequest } from './useError'

export const useSearchAccountsList = () =>
  useRequest({ mutationFn: searchAccountsList })

export const useGetAccount = () => useRequest({ mutationFn: getAccount })

export const useUpdateAccount = () => useRequest({ mutationFn: patchAccount })

export const useCreateAccount = () => useRequest({ mutationFn: createAccount })

export const useDeleteManager = () => useRequest({ mutationFn: deleteManager })

export const useAddRestaurant = () => useRequest({ mutationFn: addRestaurant })

export const useDeleteRestaurant = () =>
  useRequest({ mutationFn: deleteRestaurant })

export const useGetAccountHolder = () =>
  useRequest({ mutationFn: getAccountHolder })

export const useOtpAuth = () => useRequest({ mutationFn: otpAuth })
