import { useUserStore } from '@/store/useUserStore'
// import * as jwt from 'jsonwebtoken'
import { useModalStore } from '@/lib/useModalStore'
import { useRouter } from 'next/navigation'
import { ApiResponse } from '..'
import { errorData, getErrorData } from '../error'
import {
  SignupResult,
  TokenData,
  confirmEmailVerify,
  createCooconUserWithUpdateEmail,
  getMe,
  getRestaurantUsers,
  login,
  logout,
  refreshToken,
  requestEmailVerify,
  resetPassword,
  sendResetPasswordEmail,
  signup,
  userKeys,
  verifyResetPasswordKey,
} from '../service/user'
import { useRequest } from './useError'
import { RequestDataOptions, useRequestQuery } from './useRequestQuery'

export const useLogin = () => {
  const { updateAccessToken, updateRefreshToken } = useUserStore(
    (state) => state,
  )
  const { mutate, error, isError, isPending, isSuccess, data } = useRequest({
    mutationFn: login,
    onSuccess: async (data: ApiResponse<TokenData>) => {
      if (data.result?.accessToken) {
        // const t: any = jwt.decode(data.result?.accessToken)
        // cookieAction.setCookie('_at', data.result?.accessToken, {
        //   expires: t.exp * 1000,
        // })
        // const rt: any = jwt.decode(data.result?.refreshToken)
        // cookieAction.setCookie('_rt', data.result?.refreshToken, {
        //   expires: rt.exp * 1000,
        // })
        // await updateAccessToken(data.result?.accessToken)
        // await updateRefreshToken(data.result?.refreshToken)
        // updateToken({
        //   accessToken: data.result.accessToken,
        //   refreshToken: data.result.refreshToken,
        // })
      }
    },
  })

  return {
    login: mutate,
    error,
    isError,
    isPending,
    isSuccess,
    data,
  }
}

export const useLogout = () => {
  return useRequest({
    mutationFn: logout,
  })
}

export const useSendResetPasswordEmail = () => {
  return useRequest({
    mutationFn: sendResetPasswordEmail,
    onNetworkError: () => {},
  })
}

export const useRequestEmailVerity = () => {
  const { mutate, error, isError, data, isPending, isSuccess, status } =
    useRequest({
      mutationFn: requestEmailVerify,
      // useErrorBoundary: true,
      onSuccess: async (data: ApiResponse<boolean>) => {
        console.log(data)
      },
      onNetworkError: () => {},
    })

  return {
    mutate,
    error,
    isError,
    data,
    isPending,
    isSuccess,
    status,
  }
}

export const useConfirmEmailVerify = () => {
  return useRequest({
    mutationFn: confirmEmailVerify,
    onSuccess: async (data: ApiResponse<boolean>) => {},
    onNetworkError: () => {},
  })
}

export const useRefreshToken = () => {
  return useRequest({
    mutationFn: refreshToken,
    onSuccess: async (data: ApiResponse<TokenData>) => {},
  })
}

export const useSignup = () => {
  return useRequest({
    mutationFn: signup,
    onSuccess: async (data: ApiResponse<SignupResult>) => {},
  })
}

export const useCreateCooconUserWithUpdateEmail = () =>
  useRequest({ mutationFn: createCooconUserWithUpdateEmail })

export const useGetMe = () => useRequest({ mutationFn: getMe })

export const useGetRestaurantUsers = (
  restaurantIdx: number,
  options?: RequestDataOptions,
) =>
  useRequestQuery({
    queryKey: userKeys.restaurant(restaurantIdx),
    queryFn: () => getRestaurantUsers({ restaurantIdx }),
    options,
  })

export const useVerifyResetPasswordKey = () => {
  const router = useRouter()
  const { openModal, closeModal } = useModalStore((state: any) => state)
  return useRequest({
    mutationFn: verifyResetPasswordKey,
    onError: (error: any) => {
      openModal({
        message: errorData[error.errorCode].message || error.message,
        onOk: () => {
          closeModal()
          router.replace('/login')
        },
      })
    },
  })
}

export const useResetPassword = () => {
  const userStore = useUserStore()
  const router = useRouter()
  const { openModal, closeModal } = useModalStore((state: any) => state)
  return useRequest({
    mutationFn: resetPassword,
    onError: (error: any) => {
      if (
        error.errorCode ===
        getErrorData('NOT_FOUND_NUMBER_IN_VERIFY_LIST').errorCode
      ) {
        openModal({
          message: getErrorData(error.errorCode).message,
          onOk: () => {
            closeModal()
            router.replace('/login')
            userStore.updateVerifyKey('')
          },
        })
      }
    },
    onSuccess: () => {
      userStore.updateVerifyKey('')
    },
    onNetworkError: () => {},
  })
}
