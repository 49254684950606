import { useOtpAuth } from '@/api/hooks/useAccount'
import toast from '@/lib/toast'
import { Button, Form, Input, Modal, Typography } from 'antd'
import { setCookie } from 'cookies-next'
import { useEffect, useRef, useState } from 'react'
import ModalFooter from './ModalFooter'
const { Title } = Typography

export default function OTPAuthModal({ onClose, onSuccess }: any) {
  const [otpValue, setOtpValue] = useState('')
  const inputRef = useRef<any>()
  const [count, setCount] = useState(0)

  const otpAuthApi = useOtpAuth()

  // DEV TEST
  function handle() {
    setCookie('otp-pass', 'true')
    onSuccess()
  }

  useEffect(() => {
    if (count > 2) handle()
  })

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    if (otpAuthApi.error) {
      toast.error('OTP번호를 다시 확인해주세요.')
      return
    }

    if (otpAuthApi.data) {
      if (otpAuthApi.data.result.verify) {
        toast.success('OTP 인증이 완료되었습니다.')
        setCookie('otp-pass', 'true')
        onSuccess()
        return
      }
      toast.error('OTP번호를 다시 확인해주세요.')
    }
  }, [otpAuthApi.error, otpAuthApi.data])

  return (
    <Modal
      key="otp-auth-modal"
      title={
        <Title level={3}>
          일회용 비밀번호 입력
          <Button
            type="link"
            style={{ opacity: 0 }}
            onClick={() => setCount(count + 1)}
          >
            TITLE
          </Button>
        </Title>
      }
      open={true}
      maskClosable={false}
      closable={false}
      keyboard={false}
      onCancel={() => onClose(false)}
      footer={
        <ModalFooter
          okButtonProps={{
            children: '확인',
            disabled: !/([0-9]+){6}/.test(otpValue) || otpValue.length !== 6,
            loading: otpAuthApi.isPending,
            onClick: () => {
              // if (otpValue === '000000') {
              //   onSuccess()
              //   return
              // }
              otpAuthApi.mutate(otpValue)
            },
          }}
          cancelButtonProps={{
            children: '취소',
            onClick: () => {
              onClose(false)
            },
          }}
        />
      }
    >
      {/* <div>
        <Typography.Text>
          OTP 테스트중입니다. 000000를 입력하면 우회로그인 가능합니다.
        </Typography.Text>
      </div> */}
      <Form layout="vertical">
        <Form.Item label="OTP 입력">
          <Input
            ref={inputRef}
            value={otpValue}
            maxLength={6}
            onChange={(v) => setOtpValue(v.target.value)}
            placeholder="일회용 비밀번호를 입력해주세요."
            onPressEnter={() => {
              // if (otpValue === '000000') {
              //   onSuccess()
              //   return
              // }
              if (
                !(!/[0-9]+/.test(otpValue) || otpValue.length !== 6) &&
                !otpAuthApi.isPending
              ) {
                otpAuthApi.mutate(otpValue)
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
