import { Button, ButtonProps, Flex } from 'antd'

export default function ModalFooter({
  cancelButtonProps,
  okButtonProps,
}: {
  cancelButtonProps: ButtonProps
  okButtonProps: ButtonProps
}) {
  return (
    <Flex className="paddingTop-24" justify="center" gap="8px">
      <Button
        size="large"
        style={{ width: 120 }}
        type={cancelButtonProps.type || 'default'}
        disabled={cancelButtonProps.disabled || false}
        danger={cancelButtonProps.danger || false}
        loading={cancelButtonProps.loading || false}
        onClick={cancelButtonProps.onClick}
      >
        {cancelButtonProps.children || '취소'}
      </Button>
      <Button
        size="large"
        style={{ width: 120 }}
        type={okButtonProps.type || 'primary'}
        disabled={okButtonProps.disabled}
        danger={okButtonProps.danger || false}
        loading={okButtonProps.loading || false}
        onClick={okButtonProps.onClick}
      >
        {okButtonProps.children || '확인'}
      </Button>
    </Flex>
  )
}
